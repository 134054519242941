$font-family-public-sans: "Public Sans", sans-serif !default;
$font-family-encode-sans: "Encode Sans Expanded", sans-serif !default;

h1,
h2 {
  font-family: $font-family-encode-sans;
  font-weight: 600;
  letter-spacing: 0.4px;
  color: #3a3c3d;
}
h2 {
  font-weight: 500;
}
h3 {
  font-family: $font-family-public-sans;
  font-weight: 500;
  letter-spacing: 0.4px;
}
h4,
h5,
h6 {
  font-family: $font-family-public-sans;
  font-weight: 400;
  letter-spacing: 0.4px;
}

.btn-small {
  font-size: 1rem;
}

// .rdt_TableHeadRow {
//     background-color: #f3f2f7;
// }

.full-height-container {
  min-height: calc(100vh - 122px);
  display: flex;
  flex-direction: column;
}

.middle-page-container {
  width: 90%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  @media (min-width: 1920px) {
    width: 80%;
  }
  @media (max-width: 1200px) {
    width: 100%;
  }
  margin: 0 auto;
}

.input-text {
  color: #000;
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
}

.simulation-flow {
  &-explainer {
    margin: auto 0;
  }
  &-stepper {
    margin-top: 48px;
    margin-bottom: 48px;
  }
  &-selector-text {
    margin-bottom: 8px;
  }
  .pm-select {
    width: 50%;
    background-color: #f8f8f8;
    @media (min-width: 1920px) {
      width: 35%;
    }
    @media (max-width: 992px) {
      width: 70%;
    }
    @media (max-width: 576px) {
      width: 100%;
    }
  }

  .pm-input {
    width: 50%;
    background-color: #f8f8f8;
    @media (min-width: 1920px) {
      width: 35%;
    }
    @media (max-width: 992px) {
      width: 70%;
    }
    @media (max-width: 576px) {
      width: 100%;
    }
  }
}

form {
  padding: 16px;
}

.form-control-btns {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  margin-top: 24px;
  width: 100%;
  padding: 0 16px;
}

.confirmModal {
  &-modal {
    padding: 48px;
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  &-modalFooter {
    display: flex;
    gap: 16px;
    padding: 0 16px;
    padding-bottom: 32px;
    justify-content: center;
    max-width: 450px;
    margin: 48px auto 0;
  }
}

.stats-container {
  display: flex;
  gap: 21px;
  flex-wrap: wrap;
  .card {
    height: 100%;
    justify-content: space-between;
    & > .card-body {
      display: flex;
      align-items: center;
      justify-content: space-between;
      & > div {
        width: 100%;
      }
    }
  }
  @media (max-width: 992px) {
    flex-direction: column;
  }
  .stats-first-row {
    flex: 1;
    border-radius: 6px;
    align-self: stretch;
  }
  .stats-second-row {
    border-radius: 6px;
    display: flex;
    flex: 3;
    box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
    @media (max-width: 992px) {
      flex-direction: column;
    }
  }
}

.circle-sm {
  position: relative;
  width: 45px;
  height: 45px;
  border-radius: 45px;
  background: #f8f8f8;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
}

.circle-sm svg {
  width: 45px;
  height: 45px;
}

.circle-sm .bg {
  fill: none;
  stroke-width: 3px;
  // stroke: #ebebeb;
}

.circle-sm .progress {
  fill: none;
  stroke-width: 3px;
  stroke-linecap: round;
  stroke-dasharray: 326.56;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}

.progress_1 {
  stroke-dashoffset: 310; // 310, 290, 270, 250, 200
  animation: sm_1 0.5s ease-in-out;
}
.progress_2 {
  stroke-dashoffset: 290; // 310, 290, 270, 250, 200
  animation: sm_2 0.5s ease-in-out;
}
.progress_3 {
  stroke-dashoffset: 270; // 310, 290, 270, 250, 200
  animation: sm_3 0.5s ease-in-out;
}
.progress_4 {
  stroke-dashoffset: 250; // 310, 290, 270, 250, 200
  animation: sm_4 0.5s ease-in-out;
}
.progress_5 {
  stroke-dashoffset: 200; // 310, 290, 270, 250, 200
  animation: sm_5 0.5s ease-in-out;
}

.circle-sm .inner {
  // background: red;
  width: 18px;
  height: 18px;
  border-radius: 18px;
  position: absolute;
  top: calc(50% - 9px);
  left: calc(50% - 9px);
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
}
// 310, 290, 270, 250, 200
@keyframes sm_1 {
  from {
    stroke-dashoffset: 326.56;
  }
  to {
    stroke-dashoffset: 310;
  }
}
@keyframes sm_2 {
  from {
    stroke-dashoffset: 326.56;
  }
  to {
    stroke-dashoffset: 290;
  }
}
@keyframes sm_3 {
  from {
    stroke-dashoffset: 326.56;
  }
  to {
    stroke-dashoffset: 270;
  }
}
@keyframes sm_4 {
  from {
    stroke-dashoffset: 326.56;
  }
  to {
    stroke-dashoffset: 250;
  }
}
@keyframes sm_5 {
  from {
    stroke-dashoffset: 326.56;
  }
  to {
    stroke-dashoffset: 200;
  }
}

.savedCardTitle {
  font-family: "Public Sans" !important;
  font-style: normal;
  font-weight: normal;
  // font-size: 20px;
  line-height: 21px;
  /* identical to box height, or 105% */
  text-transform: capitalize;
  color: #5e5873;
  margin-bottom: 16px;
}

.savedCardName {
  font-family: "Public Sans" !important;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  // line-height: 28px;
  color: #5e5873;
}

.savedCardDesc {
  font-family: "Public Sans" !important;
  font-style: normal;
  font-weight: regular;
  font-size: 14px;
  // line-height: 21px;
  color: #6e6b7b;
}

.no-rad {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.spinner-border-sm {
  border-width: 0.1em;
  height: 14px;
  width: 14px;
}
.toplevel-nav {
  @media (max-width: 1199px) {
    width: 100%;
  }
}

.image-page {
  width: 450px;
  @media (max-width: 567px) {
    width: 90% !important;
  }
}

.text-black {
  color: #000 !important;
}
