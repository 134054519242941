// ================================================================================================
//     File Name: type.scss
//     Description: Type pages custom scss
//     ----------------------------------------------------------------------------------------------
//     Item Name: Vuexy  - Vuejs, HTML & Laravel Admin Dashboard Template
//     Author: PIXINVENT
//     Author URL: http://www.themeforest.net/user/pixinvent
// ================================================================================================

// text heading color
.text-body-heading {
  color: $headings-color;
  &:hover {
    color: inherit !important;
  }
}
// text body hover color if have link
.text-body {
  &[href]:hover {
    color: $link-hover-color !important;
  }
}
.pad-x-2rem {
  padding-left: 2rem;
  padding-right: 2rem;
}

.text-body-1 {
  font-size: 1.5rem;
}
.text-body-2 {
  font-size: 1.25rem;
}
.text-body-3 {
  font-size: 1.125rem;
}
.text-body-4 {
  font-size: 1rem;
}
.text-body-5 {
  font-size: 0.875rem;
}
